import Edit from '@icons/edit.svg'
import Upload from '@icons/upload.svg'
import DoNotDisturbOn from '@material-symbols/svg-500/rounded/do_not_disturb_on.svg'
import * as React from 'react'
import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  CreatedAtColumn,
  CreatedByColumn,
  EditableDateColumn,
  TestSystemGroupStatusColumn,
  TestWorkGroupColumn,
  TextColumn,
  UpdatedAtColumn,
  UpdatedByColumn,
  userDefinedColumns,
} from 'src/components/TableColumns/Columns'
import {
  ContractColumn,
  DisciplineColumn,
  ResponsibleColumn,
} from 'src/components/TableColumns/DisciplineResponsibleColumns'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import TestSystemGroupForm from 'src/components/system/test-system-groups/TestSystemGroupForm'
import TestSystemGroupInspectorPanel from 'src/components/system/test-system-groups/TestSystemGroupInspectorPanel'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { IMetaValue } from 'src/document/types/IMetaData'
import { testSystemGroupExportTemplate } from 'src/export-templates/TestSystemGroupExport'
import { useSystemTypeGroupForDomain } from 'src/query/systemTypeGroups'
import {
  IDiscipline,
  ITestSystemGroup,
  IUserData,
} from 'src/service/OrgTypes.js'
import { getStatusesForType } from 'src/service/SystemStatusService'
import { updateUserDefinedFieldsValue } from 'src/service/SystemTypeFieldService'
import {
  deleteTestSystemGroup,
  editTestSystemGroup,
  getProjectTestSystemGroup,
  massTestSystemGroup,
  testSystemGroupBulkDelete,
  testSystemGroupImportFromJson,
} from 'src/service/TestSystemGroupService.js'
import Button from 'src/ui-elements/button/Button'
import { Icons } from 'src/ui-elements/icon/Icon'
import List from 'src/ui-elements/list/List'
import { IListFilter } from 'src/ui-elements/list/ListContextProvider'
import { ISelectType, ISorting } from 'src/ui-elements/list/ListTypes'
import Loader from 'src/ui-elements/loader/Loader'
import Modal from 'src/ui-elements/modal/Modal'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { addMetaDataInfo } from 'src/utility/exportUtils'
import {
  classNames,
  constructFilterJson,
  IActiveFilter,
  capFirstLetter,
} from 'src/utility/utils'
import DataSelectors, {
  DATA_TYPES,
  ImportFiledTypes,
} from '../../../document/components/FileUpload/selectors/DataSelectors'
import { testSystemGroupImport } from '../../../export-templates/TestSystemGroupImport'
import { IMPORT_TYPE } from '../../notifications/import/ImportNotificationItem'
import ImportModal from '../../upload-item/ImportModal'

export const testSystemGroupsListImportColumns = [
  DataSelectors.defineDataField('record_id', DATA_TYPES.string),
  DataSelectors.defineDataField('title', DATA_TYPES.string),
  DataSelectors.defineDataField('description', DATA_TYPES.string),
  DataSelectors.defineDataField('planned_start', DATA_TYPES.date),
  DataSelectors.defineDataField('planned_end', DATA_TYPES.date),
  DataSelectors.defineDataField('actual_start', DATA_TYPES.date),
  DataSelectors.defineDataField('actual_end', DATA_TYPES.date),
  DataSelectors.getDataField(ImportFiledTypes.TEST_SYSTEM_GROUP_STATUSES),
  DataSelectors.getDataField(ImportFiledTypes.TEST_WORK_GROUP),
  DataSelectors.getDataField(ImportFiledTypes.DISCIPLINE),
  DataSelectors.getDataField(ImportFiledTypes.RESPONSIBLE),
]

interface ITestSystemGroupsListProps {
  projectId: number
  workGroupId?: number
  statusId?: number
  onTestSystemChange?: () => void
  selectedTestSystemGroups?: number[]
  updateSelectedTestSystemGroups?: (ids: number[]) => void
  workableList?: boolean
  itemsPerPage?: number
  disciplineId?: number
}

const TestSystemGroupList = ({
  projectId,
  workGroupId,
  statusId,
  onTestSystemChange,
  selectedTestSystemGroups,
  updateSelectedTestSystemGroups,
  workableList = true,
  itemsPerPage = 30,
  disciplineId,
}: ITestSystemGroupsListProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }
  const userContext = useContext(UserContext)
  const writeAccess = userContext.actions.hasWriteAccess('object')

  const { t } = useTranslation()
  const [testSystemGroups, setTestSystemGroups] = useState<ITestSystemGroup[]>(
    [],
  )
  const [selectedTestSystemGroup, setSelectedTestSystemGroup] =
    useState<ITestSystemGroup>()
  const [loading, setLoading] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState(1)

  const [showInspector, setShowInspector] = useState(false)
  const projectUsers = useRef<IUserData[]>([])
  const projectDiscipline = useRef<IDiscipline[]>([])
  const [reloadTable, setReloadTable] = useState(false)
  const [selectedTestSystemGroupIds, setSelectedTestSystemGroupIds] = useState<
    number[]
  >([])
  const [showMassEditModal, setShowMassEditModal] = useState<boolean>(false)
  const [showImportModal, setImportModal] = useState(false)

  const history = useHistory()
  const { addAlert } = useAlert()
  const showAlart = (type: IAlertType, title: string, text: string) => {
    addAlert({ type, title, description: text })
  }

  const reload = useCallback(() => {
    setReloadTable((n) => !n)
  }, [])

  const { data: systemTypeGroup, isLoading: isLoadingUserDefinedColumns } =
    useSystemTypeGroupForDomain('TestSystemGroup')

  const testSystemGroup = useRef(testSystemGroups)

  useEffect(() => {
    testSystemGroup.current = testSystemGroups
  })

  const onRowClick = (systemGroup: ITestSystemGroup) => {
    workGroupId
      ? history.push(
          `/systems/test_work_groups_list/testworkgroups/${workGroupId}/testsystemgroups/${systemGroup.id}`,
        )
      : history.push(
          `/systems/test_system_groups/testsystemgroups/${systemGroup.id}`,
        )
    if (onTestSystemChange) {
      onTestSystemChange()
    }
  }

  const onSelectRow = (data: number[], _clickedRow?: ISelectType) => {
    setSelectedTestSystemGroupIds(data)
    updateSelectedTestSystemGroups && updateSelectedTestSystemGroups(data)
  }

  const onRemoveItemClick = (systemGroup: ITestSystemGroup) => {
    if (workGroupId) {
      editTestSystemGroup({
        id: systemGroup.id,
        test_work_group_id: null,
      }).then(reload)
    } else {
      setShowDeleteModal((n) => !n)
      setSelectedTestSystemGroup(systemGroup)
    }
  }

  const closeDeleteModal = () => {
    setShowDeleteModal((n) => !n)
  }

  const openCreateModal = () => {
    setShowCreateModal((n) => !n)
  }

  const closeCreateModal = () => {
    setShowCreateModal((n) => !n)
    reload()
  }

  const deleteSystemGroup = () => {
    if (selectedTestSystemGroup?.id) {
      deleteTestSystemGroup(selectedTestSystemGroup?.id).then(reload)
      setShowDeleteModal((n) => !n)
    }
  }

  const saveSystemGroup = useCallback(
    (value: ITestSystemGroup) => {
      editTestSystemGroup(value).then(() => {
        reload()
      })
    },
    [reload],
  )

  const updateUser = (
    id: number,
    responsibleId: number,
    disciplineId: number,
  ) => {
    const dataField: ITestSystemGroup = {
      id,
      discipline_id: disciplineId,
      responsible_id: responsibleId,
    }
    saveSystemGroup(dataField)
  }

  const updateMetaFiled = (data: IMetaValue, _id: number) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        setReloadTable((n) => !n)
      })
    }
  }

  const userDefinedAttributesColumns = systemTypeGroup
    ? userDefinedColumns(
        systemTypeGroup.optional_fields,
        updateMetaFiled,
        'meta_data',
        true,
        false,
        !writeAccess,
      )
    : []

  const onFieldChange = (
    field: string,
    key: number,
    value: string | number | null,
  ) => {
    const system = { id: key, [field]: value }
    saveSystemGroup(system)
  }

  const columns = [
    TextColumn(
      'test_object_group_id',
      'record_id',
      '200',
      (key, value) => onFieldChange('record_id', key, value),
      !writeAccess,
    ),
    TextColumn(
      'title',
      'title',
      '200',
      (key, value) => onFieldChange('title', key, value),
      !writeAccess,
    ),
    TestSystemGroupStatusColumn(
      () => getStatusesForType(projectId, 'TestSystemGroup'),
      (id, status_id) =>
        onFieldChange('test_system_group_status_id', id, status_id ?? ''),
      !writeAccess,
    ),
    TextColumn(
      'description',
      'description',
      '200',
      (key, value) => onFieldChange('description', key, value),
      !writeAccess,
    ),
    TestWorkGroupColumn(!!workGroupId),
    TextColumn(
      'percent_completed',
      'percent_completed',
      '200',
      (key, value) => onFieldChange('percent_completed', key, value),
      true,
      'number',
    ),
    EditableDateColumn(
      'planned_start',
      'planned_start',
      'planned_start',
      (plannedStart, id) => {
        onFieldChange(
          'planned_start',
          id ?? -1,
          plannedStart ? plannedStart.toISOString() : null,
        )
        onFieldChange(
          'planned_end',
          id ?? -1,
          plannedStart ? plannedStart.add(6, 'weeks').toISOString() : null,
        )
      },
      { nullable: true, disabled: !writeAccess },
    ),
    EditableDateColumn(
      'planned_end',
      'planned_end',
      'planned_end',
      (plannedEnd, id) =>
        onFieldChange(
          'planned_end',
          id ?? -1,
          plannedEnd ? plannedEnd.toISOString() : null,
        ),
      { nullable: true, disabled: !writeAccess },
    ),
    EditableDateColumn(
      'actual_start',
      'actual_start',
      'actual_start',
      (actualStart, id) =>
        onFieldChange(
          'actual_start',
          id ?? -1,
          actualStart ? actualStart.toISOString() : null,
        ),
      { nullable: true, disabled: !writeAccess },
    ),
    EditableDateColumn(
      'actual_end',
      'actual_end',
      'actual_end',
      (actualEnd, id) =>
        onFieldChange(
          'actual_end',
          id ?? -1,
          actualEnd ? actualEnd.toISOString() : null,
        ),
      { nullable: true, disabled: !writeAccess },
    ),
    ResponsibleColumn(
      projectId,
      projectUsers,
      projectDiscipline,
      updateUser,
      !writeAccess,
    ),
    DisciplineColumn(
      projectId,
      projectDiscipline,
      projectUsers,
      (id: number, discipline_id: number, responsible_id: number) => {
        const system = { id, discipline_id, responsible_id }
        saveSystemGroup(system)
      },
      !writeAccess,
    ),
    ContractColumn(),
    ...userDefinedAttributesColumns,
    CreatedByColumn(),
    UpdatedByColumn(),
    CreatedAtColumn(),
    UpdatedAtColumn(),
  ]

  const handlePreviewClick = (e: MouseEvent, data: ITestSystemGroup) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedTestSystemGroup(data)
    setShowInspector(true)
  }

  const onCloseInspector = () => {
    setShowInspector(false)
    setSelectedTestSystemGroup(undefined)
  }

  const taskFilter = (activeFilters: IActiveFilter, page: number) => {
    setLoading(true)
    getProjectTestSystemGroup(
      projectId,
      page,
      activeFilters,
      itemsPerPage,
    ).then((res) => {
      setTestSystemGroups(res.test_system_groups)
      setTotalPages(res.pages)
      setLoading(false)
    })
  }

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    if (statusId) {
      activeFilters['test_system_group_status'] = []
      activeFilters['test_system_group_status'].push(`${statusId}`)
    }

    if (workGroupId) {
      activeFilters['test_work_group'] = []
      activeFilters['test_work_group'].push(`${workGroupId}`)
    }

    if (disciplineId) {
      activeFilters['discipline'] = []
      activeFilters['discipline'].push(`${disciplineId}`)
    }

    activeFilters['sort'] = currentSorting
    taskFilter(activeFilters, page)
  }

  const bulkRemove = () => {
    if (!workGroupId) return
    massTestSystemGroup(projectId, selectedTestSystemGroupIds, {
      test_work_group_id: null,
    }).then(reload)
  }

  const onBulkDelete = () => {
    testSystemGroupBulkDelete(projectId, selectedTestSystemGroupIds).then(
      () => {
        showAlart(
          'success',
          t('successfully_deleted'),
          t('selected_test_system_group_are_deleted'),
        )
        setReloadTable((n) => !n)
        setSelectedTestSystemGroupIds([])
      },
    )
  }

  const openMassEditModal = () => {
    if (selectedTestSystemGroupIds.length === 0) {
      showAlart('info', t('items_not_selected'), t('select_item_to_edit'))
      return
    }
    setShowMassEditModal(true)
  }

  const closeMassEditModal = () => {
    setShowMassEditModal(false)
    setSelectedTestSystemGroupIds([])
    setReloadTable((n) => !n)
  }

  return (
    <div className={styleClass.root}>
      {(loading || isLoadingUserDefinedColumns) && (
        <Loader centerLoader={true} />
      )}
      {!isLoadingUserDefinedColumns && (
        <List
          actionButton={
            workableList ? (
              <span className="flex items-center">
                {writeAccess && (
                  <Button
                    onClick={openCreateModal}
                    type={Button.ButtonType.PRIMARY}
                  >
                    {t('new_test_system_group')}
                  </Button>
                )}
                {writeAccess && (
                  <>
                    <Button
                      type={Button.ButtonType.PRIMARY}
                      onClick={openMassEditModal}
                    >
                      <Edit className={'fill-white mr-1'} />
                      {capFirstLetter(t('edit'))}
                    </Button>
                    <Button
                      type={Button.ButtonType.PRIMARY}
                      onClick={() => setImportModal(true)}
                    >
                      <Upload className={'fill-white mr-1'} />
                      {t('import')}
                    </Button>
                  </>
                )}
                {workGroupId && (
                  <Button
                    type={Button.ButtonType.DEFAULT}
                    onClick={bulkRemove}
                    title={t('remove_test_system_group')}
                  >
                    <DoNotDisturbOn className="fill-red" />
                  </Button>
                )}
              </span>
            ) : undefined
          }
          totalPages={totalPages}
          getFilteredData={getFilteredData}
          data={testSystemGroups}
          exportTemplate={
            workableList
              ? addMetaDataInfo(
                  testSystemGroupExportTemplate,
                  systemTypeGroup?.optional_fields ?? [],
                )
              : undefined
          }
          sortBackend={true}
          filterResetOption={true}
          itemsPerPage={itemsPerPage}
          pagination={true}
          columns={columns}
          tableName={'testSystemGroupsList'}
          onRowClick={onRowClick}
          reload={reloadTable}
          actionMenu={
            workableList && writeAccess
              ? [
                  {
                    name: t('remove'),
                    action: onRemoveItemClick,
                    icon: Icons.REMOVE_CIRCLE_BLUE,
                  },
                ]
              : []
          }
          onPreviewClick={handlePreviewClick}
          selectedRows={selectedTestSystemGroups ?? selectedTestSystemGroupIds}
          onBulkDelete={onBulkDelete}
          bulkDelete={workableList && writeAccess}
          onSelectRow={onSelectRow}
          isRowSelectable={true}
        />
      )}

      <Modal
        show={showMassEditModal}
        closeModal={closeMassEditModal}
        title={t('edit_multiple_test_system_groups')}
        maxWidth={800}
      >
        <TestSystemGroupForm
          editingMode={true}
          projectId={projectId}
          testSystemGroupIds={selectedTestSystemGroupIds}
          closeModal={closeMassEditModal}
        />
      </Modal>

      {selectedTestSystemGroup && showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={closeDeleteModal}
          onDelete={deleteSystemGroup}
          itemIdnType={`${selectedTestSystemGroup.record_id}`}
          itemName={`${selectedTestSystemGroup.record_id}`}
        />
      )}

      {selectedTestSystemGroup?.id && showInspector && (
        <TestSystemGroupInspectorPanel
          projectId={projectId}
          testSystemGroupId={selectedTestSystemGroup?.id}
          open={showInspector}
          onClose={onCloseInspector}
          key={selectedTestSystemGroup?.id}
          onUpdate={() => setReloadTable((n) => !n)}
        />
      )}
      <Modal
        show={showCreateModal}
        closeModal={openCreateModal}
        title={t('add_object_group')}
        maxWidth={800}
      >
        <TestSystemGroupForm
          editingMode={false}
          projectId={projectId}
          closeModal={closeCreateModal}
        />
      </Modal>
      {showImportModal && (
        <ImportModal
          columns={testSystemGroupsListImportColumns}
          import_type={IMPORT_TYPE.TEST_SYSTEM_GROUP_IMPORT}
          show={showImportModal}
          close={() => {
            setImportModal(false)
            reload()
          }}
          useDefinedField={systemTypeGroup?.optional_fields ?? []}
          importApi={testSystemGroupImportFromJson}
          modal_title={'upload_test_system_groups'}
          template={testSystemGroupImport}
          uploadUrl={`{ORG_URL}test_system_groups/validate_for_import_job`}
        />
      )}
    </div>
  )
}

export default TestSystemGroupList
