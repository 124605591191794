import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import {
  getMetaDataValues,
  loadMetaValues,
} from 'src/components/system/SystemUtil'
import TestSystemGroupFields from 'src/components/system/test-system-groups/TestSystemGroupFields'
import TestSystemGroupStatusFields from 'src/components/system/test-system-groups/TestSystemGroupStatusFields'
import { ModalContext } from 'src/context/ModalContextProvider/ModalContext'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import MetaDataFieldsForPage from 'src/document/components/DocumentCreateModal/MetaDataFieldsForPage'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import InspectorSections from 'src/document/components/Inspector/InspectorSections'
import { IMetaValue } from 'src/document/types/IMetaData'
import {
  ISystem,
  ISystemTypeGroup,
  ITestSystemGroup,
} from 'src/service/OrgTypes'
import { getTestSystemGroups } from 'src/service/SystemService'
import { getProjectSystemTypeGroupsPerDomain } from 'src/service/SystemTypeGroupService'
import { getSingleTestSystemGroup } from 'src/service/TestSystemGroupService'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import { Icons } from 'src/ui-elements/icon/Icon'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { modalUrlBuilder } from 'src/utility/utils'

interface ITestSystemGroupInspectorPanel {
  projectId: number
  testSystemGroupId: number
  open: boolean
  onClose: () => void
  onUpdate: () => void
  defaultIndex?: number
}

const TestSystemGroupInspectorPanel: React.FC<
  ITestSystemGroupInspectorPanel
> = ({
  projectId,
  testSystemGroupId,
  open,
  onClose,
  onUpdate,
  defaultIndex,
}) => {
  const { t } = useMultipleKeysTranslation()
  const userContext = useContext(UserContext)

  const [testSystemGroup, setTestSystemGroup] = useState<ITestSystemGroup>()
  const writeAccess =
    userContext.actions.hasWriteAccess('object') ||
    testSystemGroup?.responsible_id === userContext.state.user.id

  const [systems, setSystems] = useState<ISystem[]>([])
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [systemTypeGroup, setSystemTypeGroup] = useState<
    ISystemTypeGroup | undefined
  >(undefined)
  const history = useHistory()
  const modalContext = useContext(ModalContext)

  const loadTestSystemGroup = (value?: ISystemTypeGroup) => {
    getSingleTestSystemGroup(testSystemGroupId).then(
      (res: ITestSystemGroup) => {
        setTestSystemGroup(res)
        if (value || systemTypeGroup) {
          const metaData = getMetaDataValues(res.meta_data)
          setOptionalFields(
            loadMetaValues(
              testSystemGroupId,
              'TestSystemGroup',
              value?.optional_fields ?? systemTypeGroup?.optional_fields,
              metaData,
            ),
          )
        }
      },
    )
  }

  useEffect(() => {
    getProjectSystemTypeGroupsPerDomain(projectId, 'TestSystemGroup').then(
      (resp: ISystemTypeGroup[]) => {
        const value = resp.pop()
        setSystemTypeGroup(value)
        loadTestSystemGroup(value)
      },
    )
  }, [testSystemGroupId, projectId])

  useEffect(() => {
    loadTestSystemGroup()
  }, [testSystemGroupId])

  const getSystems = () => {
    getTestSystemGroups(testSystemGroupId).then((res) => {
      setSystems(res)
    })
  }

  const onTestSystemGroupUpdate = () => {
    loadTestSystemGroup()
    onUpdate()
  }

  const getMetaDataContent = (): JSX.Element => (
    <div className={'flex flex-col -ml-4 -mt-2'}>
      {optionalFields.length > 0 && (
        <MetaDataFieldsForPage
          onFieldsUpdate={onTestSystemGroupUpdate}
          fields={optionalFields}
          required={false}
          editMode={false}
          inspectorPanel={true}
          disabled={!writeAccess}
        />
      )}
    </div>
  )

  const onSystemClick = (id: number) => {
    const modalURL = modalUrlBuilder(projectId, 'system', id)
    history.push(modalURL)
    modalContext.actions.checkURL()
  }

  const getRows = (): Array<{
    name: string
    icon: string | JSX.Element
    activeIcon?: string | JSX.Element
    content: JSX.Element
    onClick?: () => void
  }> => [
    {
      name: t('details'),
      icon: Icons.SYSTEM_GRAY,
      activeIcon: Icons.SYSTEM_BLACK,
      content: (
        <div className="-mt-3 -ml-6">
          <InlineComponentsWrapper padding={'left'}>
            <TestSystemGroupFields
              testSystemGroup={testSystemGroup}
              setTestSystemGroup={setTestSystemGroup}
              onUpdate={onTestSystemGroupUpdate}
              inspectorPanel
            />
            <TestSystemGroupStatusFields
              testSystemGroup={testSystemGroup}
              setTestSystemGroup={setTestSystemGroup}
              onUpdate={onTestSystemGroupUpdate}
              inspectorPanel
            />
          </InlineComponentsWrapper>
        </div>
      ),
      onClick: loadTestSystemGroup,
    },
    {
      name: t('metadata'),
      icon: Icons.DATABASE_GREY,
      activeIcon: Icons.DATABASE,
      content: getMetaDataContent(),
      onClick: loadTestSystemGroup,
    },
    {
      name: t('systems'),
      icon: Icons.SYSTEM_GRAY,
      activeIcon: Icons.SYSTEM,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('system_number'),
            t('system_name'),
            t('system_type'),
            t('status'),
          ]}
          rowsData={systems?.map((system) => {
            return {
              cells: [
                system?.record_id,
                system?.name,
                system?.system_type?.name,
                system?.system_status?.name,
              ],
              id: system.id,
            }
          })}
          handleClick={onSystemClick}
        />
      ),
      onClick: getSystems,
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: (
        <Comments parentId={testSystemGroupId} parentType="TestSystemGroup" />
      ),
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: (
        <ChangeLog parentId={testSystemGroupId} parentType="TestSystemGroup" />
      ),
    },
  ]

  return (
    <FixedPane
      title={
        testSystemGroup?.record_id
          ? testSystemGroup?.record_id + ' - ' + testSystemGroup?.title
          : t('loading...')
      }
      show={open}
      onClose={() => onClose()}
      className={'w-[700px]'}
      noMainContent={true}
      detailPageData={{
        key: DetailPageKeys.TEST_SYSTEM_GROUP,
        ids: {
          testSystemGroupId: testSystemGroupId,
        },
      }}
    >
      <div className={'flex flex-col min-h-full'}>
        <InspectorSections
          defaultIndex={defaultIndex ?? 0}
          sections={getRows()}
        />
      </div>
    </FixedPane>
  )
}
export default TestSystemGroupInspectorPanel
