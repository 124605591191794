import moment from 'moment'
import * as React from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabPanel } from 'react-tabs'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import CopyUrl from 'src/components/copy/CopyUrl'
import DocumentsList from 'src/components/document/DocumentsList'
import ImprovementPanel from 'src/components/improvement/improvement-panel/ImprovementPanel'
import QrModal from 'src/components/qr/QrModal'
import ActionsList from 'src/components/task/ActionsList'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { IMetaValue } from 'src/document/types/IMetaData'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { getFilteredProjectImprovementsWithPagination } from 'src/service/ImprovementsService'
import { getProjectPlanFreezePeriod } from 'src/service/ProjectService'
import { statusTypes } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { getMainprocessTeams, getProjectTeams } from 'src/service/TeamService'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Icon from 'src/ui-elements/icon/Icon'
import PageHeader from 'src/ui-elements/page-display/PageHeader'
import PageRoot from 'src/ui-elements/page-display/PageRoot'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { ContentTabsWrapper } from 'src/ui-elements/tabs/ContentTabs'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import { capFirstLetter, classNames } from 'src/utility/utils'
import { getDelivery, updateDelivery } from '../../../service/DeliveryService'
import {
  IDelivery,
  IImprovement,
  ISystemTypeGroup,
} from '../../../service/OrgTypes'
import {
  getProjectKeypoints,
  getProjectProcesses,
} from '../../../service/ProcessService'
import { getProjectSystemTypeGroupsPerDomain } from '../../../service/SystemTypeGroupService'
import {
  getDeliveryErrorMessage,
  getErrorMessage,
  StructureValidationError,
  ValidationError,
} from '../../../service/ValidationErrors'
import { metaDataSection } from '../../TableColumns/Columns'
import { getMetaDataValues, loadMetaValues } from '../../system/SystemUtil'
import ChangeLogList from '../main-process/ChangeLogList'
import KeypointChangeLogModal from '../main-process/KeypointChangeLogModal'
import DependencyDelivery from './DependencyDelivery'

interface IDeliveryPageProps {
  deliveryId: number
  onOpenItem: (id: number, type: string, parentId?: number) => void
  reloadTree?: () => void
}

export interface IInputErrorMessage {
  for: string
  errorMessage: string
}

const DeliveryPage: React.FC<IDeliveryPageProps> = ({
  deliveryId,
  onOpenItem,
  reloadTree,
}: IDeliveryPageProps) => {
  const styleClass = {
    actionBtnContainer: classNames(
      'mx-2',
      'w-8',
      'h-8',
      'p-0.5',
      'flex',
      'justify-center',
      'items-center',
      'border-gray-300',
      'border',
      'rounded-full',
      'hover:cursor-pointer',
    ),
  }

  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const { t } = useTranslation()
  const [delivery, setDelivery] = useState<IDelivery>({} as IDelivery)
  const [dependentDeliveries, setDependentDeliveries] = useState<IDelivery[]>(
    [],
  )
  const deliveryIdRef = useRef(deliveryId)
  const [dependencyStatus, setDependencyStatus] = useState<boolean>(false)
  const [improvements, setImprovements] = useState<IImprovement[]>([])

  const [deliveryHasOpenTasks, setDeliveryHasOpenTasks] =
    useState<boolean>(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const { isBaselineAdmin } = projectContext.state

  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [systemTypeGroup, setSystemTypeGroup] = useState<
    ISystemTypeGroup | undefined
  >(undefined)
  const detailItems: string[] = [
    capFirstLetter(t('details')),
    capFirstLetter(t('metadata')),
  ]

  const [detailTabIndex, setDetailTabIndex] = useState<number>(0)

  const [tabIndex, setTabIndex] = useState<number>(0)
  const tabItems: string[] = [
    t('tasks'),
    t('change_log'),
    t('incoming_dependencies'),
    t('outgoing_dependencies'),
    t('attachment'),
    t('comments'),
    t('change_log'),
  ]
  const { addChangesToAppendWhenKeyUpdates, getChangesForUpdate } =
    useInlineDependencyUpdate<IDelivery>(setDelivery, delivery)
  const [showImprovementPanel, setShowImprovementPanel] =
    useState<boolean>(false)

  const [qrString, setQrString] = useState<string>('')
  const [showQrModal, setShowQrModal] = useState<boolean>(false)

  const [changeLogModal, setChangeLogModal] = useState<boolean>(false)
  const [changeLogReason, setChangeLogReason] = useState<string>('')
  const endDateRef = useRef(moment())

  const { addAlert } = useAlert()

  const showAlart = (type: IAlertType, titleForAlart: string, text: string) => {
    addAlert({ type, title: titleForAlart, description: text })
  }
  const [planFreezDuration, setPlanFreezDuration] = useState<number>(0)

  const generateQrCode = () => {
    const url = window.location.href.split('?')[0]
    setQrString(url)
  }

  const toggleQrModal = () => {
    generateQrCode()
    setShowQrModal(true)
  }

  const onChangelogReasonChange = (value: any) => {
    setChangeLogReason(value)
  }

  useEffect(() => {
    if (dependentDeliveries) {
      const dependencyStatusVal =
        dependentDeliveries.filter(
          (d: IDelivery) =>
            d !== undefined &&
            (d.status === 'open' || d.status === 'in_progress'),
        ).length > 0
      let dependencyDateVal: any = null
      dependentDeliveries.forEach((d: IDelivery) => {
        if (d !== undefined) {
          if (dependencyDateVal) {
            if (moment(d.endTime).isAfter(dependencyDateVal, 'day')) {
              dependencyDateVal = moment(d.endTime)
            }
          } else {
            dependencyDateVal = moment(d.endTime)
          }
        }
      })
      setDependencyStatus(dependencyStatusVal)
    }
  }, [dependentDeliveries])

  const reload = (value?: ISystemTypeGroup) => {
    if (deliveryIdRef.current !== deliveryId) {
      deliveryIdRef.current = deliveryId
    }
    getDelivery(projectId, deliveryId).then((res) => {
      setDelivery(res)
      setDeliveryHasOpenTasks(res.open_children ? res.open_children > 0 : false)
      setDependentDeliveries(
        res.dependent_deliveries
          ? res.dependent_deliveries.map(
              (dependentDeliveriesVal: any) =>
                dependentDeliveriesVal.dependent_on_delivery,
            )
          : [],
      )
      if (value || systemTypeGroup) {
        const metaData = getMetaDataValues(res.meta_data)
        setOptionalFields(
          loadMetaValues(
            deliveryId,
            'Delivery',
            value?.optional_fields ?? systemTypeGroup?.optional_fields,
            metaData,
          ),
        )
      }
      setOptionalFields(res.user_defined_values ?? [])
      setFirstLoad(false)
    })
  }

  useEffect(() => {
    getProjectSystemTypeGroupsPerDomain(projectId, 'Delivery').then(
      (resp: ISystemTypeGroup[]) => {
        const value = resp.pop()
        setSystemTypeGroup(value)
        reload(value)
      },
    )
    getProjectPlanFreezePeriod(projectId, 'delivery').then((res) => {
      setPlanFreezDuration(res.plan_freeze_period)
    })
  }, [projectId, deliveryId])

  const isEndTimeValid = (endTimeValue: moment.Moment) => {
    if (!endTimeValue) {
      return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
    }

    if (endTimeValue) {
      if (
        delivery.key_point &&
        delivery.key_point.endTime &&
        moment(endTimeValue).isAfter(moment(delivery.key_point.endTime), 'day')
      ) {
        return getDeliveryErrorMessage(
          StructureValidationError.PAST_PARENT_DEADLINE,
          t,
          moment(delivery.key_point.endTime).format('L'),
        )
      }

      const improvement = improvements.find(
        (u) => u.id === delivery.improvement_id,
      )
      if (improvement && improvement.deadline) {
        if (moment(endTimeValue).isAfter(moment(improvement.deadline), 'day')) {
          return getDeliveryErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
            t,
            moment(improvement.deadline).format('L'),
          )
        }
      }
    }
  }

  useEffect(() => {
    if (firstLoad) {
      const item = {
        keypoints: delivery.key_point ? [delivery.key_point] : [],
        disciplines: delivery.discipline ? [delivery.discipline] : [],
        users: delivery.responsible ? [delivery.responsible] : [],
        mainprocess: delivery.main_process ? [delivery.main_process] : [],
        team: delivery.team ? [delivery.team] : [],
        tag: delivery.tag ? [delivery.tag] : [],
        improvements: delivery.improvement ? [delivery.improvement] : [],
      }
      setImprovements(item.improvements)
    }
  }, [delivery, firstLoad])

  const onChangeInput = async (update: Partial<IDelivery>) => {
    const freezUntil =
      planFreezDuration > 0
        ? moment()
            .startOf('week')
            .add(planFreezDuration - 1, 'weeks')
            .endOf('week')
        : moment().startOf('day')
    const movedToTheFuture: boolean = moment(update.endTime).isAfter(
      delivery.endTime,
      'day',
    )
    if (delivery?.id) {
      if (
        update.endTime &&
        movedToTheFuture &&
        moment(delivery.endTime).isBefore(freezUntil, 'day')
      ) {
        setChangeLogReason('')
        setChangeLogModal(true)
        endDateRef.current = moment(update.endTime)
      } else {
        const allUpdates = getChangesForUpdate(update)
        setDelivery({ ...delivery, ...allUpdates })
        updateDelivery(
          {
            ...convertUndefinedToNull(allUpdates),
            id: delivery?.id,
          },
          delivery?.id,
        ).then(() => {
          reload()
        })
        if (update.name) reloadTree?.()
      }
    }
  }
  const getDependencyError = () => {
    if (dependencyStatus && delivery.status === 'done') {
      return getDeliveryErrorMessage(
        StructureValidationError.OPEN_DEPENDENCY,
        t,
      )
    } else {
      return ''
    }
  }

  const onStatusSelect = () => {
    if (!delivery.update_access) {
      showAlart('error', t('access_limited'), t('do_not_have_access_to_edit'))
      return
    }

    if (delivery.open_children && delivery.open_children > 0) {
      showAlart(
        'error',
        t('an_error_occurred'),
        getDeliveryErrorMessage(
          StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
          t,
        ),
      )
      return
    }

    if (dependencyStatus) {
      showAlart(
        'error',
        t('an_error_occurred'),
        getDeliveryErrorMessage(StructureValidationError.OPEN_DEPENDENCY, t),
      )
      return
    }

    const updatedDelivery = { ...delivery, status: 'done' }
    updateDelivery(
      updatedDelivery,
      updatedDelivery.id ? updatedDelivery.id : 0,
    ).then(() => {
      reload()
    })
  }

  const onCancelChangelogModal = () => {
    setChangeLogModal((prevState) => !prevState)
  }

  const toggleChangelogModal = () => {
    setChangeLogModal((prevState) => !prevState)
  }
  const onChangeLogSubmit = () => {
    toggleChangelogModal()
    updateDelivery(
      {
        endTime: endDateRef.current,
        change_reason: changeLogReason,
        id: delivery?.id,
      },
      deliveryId,
    ).then(() => {
      reload()
    })
  }

  const extraButtons = () => {
    return (
      <div className="flex flex-row flex-wrap items-center">
        {delivery.status && delivery.status !== 'done' && (
          <Button
            type={ButtonType.SUCCESS}
            size={Button.ButtonSize.SMALL}
            onClick={onStatusSelect}
          >
            {t('done')}
          </Button>
        )}

        <span className={styleClass.actionBtnContainer}>
          <CopyUrl />
        </span>
        <span className={styleClass.actionBtnContainer}>
          <a className="flex items-center">
            <Icon
              style={{ width: 20, height: 20 }}
              icon={Icon.IconType.QR}
              onClick={toggleQrModal}
            />
          </a>
        </span>
        {delivery.improvement_id ? (
          <Button
            size={Button.ButtonSize.SMALL}
            onClick={toggleImprovementPanel}
          >
            {t('see_improvement_measures')}
          </Button>
        ) : null}
      </div>
    )
  }

  const toggleImprovementPanel = () => {
    setShowImprovementPanel((v) => !v)
  }

  const mainSection = () => {
    return (
      <div className="flex">
        <InlineComponentsWrapper padding="left" border={'right'}>
          <TextInlineInputCompontent
            label={t('title')}
            value={delivery?.name}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ name: newValue })
            }}
            validate={(value) => {
              if (value === undefined || value === '')
                return getErrorMessage(ValidationError.MISSING_TITLE, t)
              return
            }}
          />

          <SelectorInlineInputComponent
            items={statusTypes(t)}
            label={t('status')}
            getItemLabel={(stat) => stat?.name}
            initialItem={{
              id: delivery?.status ?? '',
              name:
                statusTypes(t).find(
                  (statData) => statData.id === delivery?.status,
                )?.name ?? '',
            }}
            validate={(value) => {
              if (deliveryHasOpenTasks && value === 'done') {
                return getDeliveryErrorMessage(
                  StructureValidationError.HAS_OPEN_CHILDREN,
                  t,
                )
              }
              if (dependencyStatus && value === 'done') {
                return getDeliveryErrorMessage(
                  StructureValidationError.OPEN_DEPENDENCY,
                  t,
                )
              }
              return
            }}
            selectedId={delivery?.status}
            onValueSubmitted={(stat) => {
              onChangeInput({ status: stat })
            }}
            inspectorPanel={false}
          />

          <DateTimeInlineInputComponent
            label={t('deadline')}
            selectedTime={delivery?.endTime}
            onValueSubmitted={(deadline) => {
              onChangeInput({ endTime: deadline })
            }}
            validate={(value) => {
              if (value) {
                return isEndTimeValid(value)
              }
              return
            }}
            inspectorPanel={false}
          />

          <DateTimeInlineInputComponent
            disabled={!isBaselineAdmin}
            label={t('baseline_date')}
            selectedTime={delivery?.baseline}
            onValueSubmitted={(baselinedate) => {
              onChangeInput({ baseline: baselinedate })
            }}
            inspectorPanel={false}
          />
          <TextInlineInputCompontent
            label={t('description')}
            value={delivery?.description}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ description: newValue })
            }}
            textArea={true}
          />
          <TextInlineInputCompontent
            label={t('assumptions')}
            value={delivery?.assumptions}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ assumptions: newValue })
            }}
            textArea={true}
          />
          <TextInlineInputCompontent
            label={t('result')}
            value={delivery?.notes}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ notes: newValue })
            }}
            textArea={true}
          />
        </InlineComponentsWrapper>
        <InlineComponentsWrapper padding="left" border={undefined}>
          <TextInlineInputCompontent
            label={t('duration_days')}
            value={`${delivery?.duration ? delivery.duration : 0}`}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({ duration: parseInt(`${newValue}`, 10) })
            }}
            validate={(newValue) => {
              if (newValue?.length && isNaN(+newValue)) {
                return t('must_be_a_number')
              }
              if (newValue && +newValue < 0) {
                return getErrorMessage(ValidationError.NEGATIVE_DURATION, t)
              }
              return undefined
            }}
          />
          <TextInlineInputCompontent
            label={t('delay_days')}
            disabled={true}
            value={`${delivery?.delay ?? 0}`}
          />
          <TextInlineInputCompontent
            label={t('tasks')}
            disabled={true}
            value={`${delivery?.done_children || 0}/${
              (delivery?.open_children || 0) + (delivery?.done_children || 0)
            }`}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectKeypoints(projectId)}
            label={t('keypoint')}
            getItemLabel={(keypoint) =>
              `${keypoint?.record_id} - ${keypoint?.name}`
            }
            initialItem={delivery?.key_point}
            validate={(value) => {
              if (!value || value < 1) {
                return getErrorMessage(ValidationError.MISSING_KEY_POINT, t)
              }
              if (delivery.status) {
                if (
                  value &&
                  delivery?.key_point?.status === 'done' &&
                  delivery.status !== 'done'
                ) {
                  return getDeliveryErrorMessage(
                    StructureValidationError.PARENT_IS_CLOSED,
                    t,
                  )
                }
              }
              return
            }}
            selectedId={delivery?.key_point_id}
            onValueSubmitted={(val) => {
              onChangeInput({ key_point_id: val })
            }}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectDisciplines(projectId)}
            label="discipline"
            initialItem={delivery?.discipline}
            getItemLabel={(discipline) =>
              `${discipline?.shortName} - ${discipline?.name}`
            }
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('discipline'),
                })
              return
            }}
            selectedId={delivery?.discipline_id}
            onValueSubmitted={(discipline_id) => {
              addChangesToAppendWhenKeyUpdates('responsible_id', {
                discipline_id,
              })
            }}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              delivery?.discipline_id
                ? getDisplineUsers(delivery?.discipline_id)
                : getProjectUsersWithDisciplines(projectId)
            }
            label="responsible"
            getItemLabel={(responsible) =>
              `${responsible?.firstName} ${responsible?.lastName}`
            }
            initialItem={delivery?.responsible}
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('responsible'),
                })
              return
            }}
            selectedId={delivery?.responsible_id ?? 0}
            onValueSubmitted={(responsible_id) => {
              onChangeInput({ responsible_id })
            }}
            dependencies={[delivery?.discipline_id]}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            label={'contract'}
            disabled={true}
            selectedId={delivery?.contract_id ?? ''}
            getItemLabel={(contract) =>
              `${contract?.contractNumber} - ${contract?.contractName}`
            }
            initialItem={delivery?.contract}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectProcesses(projectId)}
            label={t('main_process')}
            getItemLabel={(process) =>
              `${process?.record_id} - ${process?.name}`
            }
            initialItem={delivery?.main_process}
            selectedId={delivery?.main_process_id}
            onValueSubmitted={(val) => {
              onChangeInput({
                main_process_id: val,
                team_id: undefined,
              })
            }}
            inspectorPanel={false}
          />

          <SelectorInlineInputComponent
            getItems={() =>
              delivery?.main_process_id
                ? getMainprocessTeams(delivery?.main_process_id)
                : getProjectTeams(projectId)
            }
            label={t('team')}
            getItemLabel={(team) => team?.name}
            initialItem={delivery?.team}
            selectedId={delivery?.team_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ team_id: val })
            }}
            cancelButton={true}
            dependencies={[delivery?.main_process_id]}
            inspectorPanel={false}
          />

          <SelectorInlineInputComponent
            getItems={() => getProjectTags(projectId)}
            label={t('type')}
            getItemLabel={(tag) => tag?.name}
            initialItem={delivery?.tag}
            selectedId={delivery?.tag_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ tag_id: val })
            }}
            cancelButton={true}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              getFilteredProjectImprovementsWithPagination(projectId).then(
                (res) => res.items,
              )
            }
            label={t('improvement')}
            getItemLabel={(improvement) => improvement?.title}
            initialItem={delivery?.improvement}
            selectedId={delivery?.improvement_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ improvement_id: val })
            }}
            cancelButton={true}
            inspectorPanel={false}
          />
          <TextInlineInputCompontent
            label={t('reporter')}
            value={`${delivery?.user?.firstName} ${delivery?.user?.lastName}`}
            disabled={true}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={`${delivery?.created_at}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={`${delivery?.updated_at}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>
      </div>
    )
  }
  return (
    <PageRoot>
      <PageHeader
        title={
          delivery?.name
            ? `${delivery?.record_id} - ${delivery?.name}`
            : t('loading...')
        }
        additionalButtons={extraButtons()}
      />
      <div className="flex py-2">
        {optionalFields.length > 0 ? (
          <ContentTabsWrapper
            tabIndex={detailTabIndex}
            tabItems={detailItems}
            onSelect={(index) => setDetailTabIndex(index)}
          >
            <TabPanel>
              <>{mainSection()}</>
            </TabPanel>
            <TabPanel>
              <div className="flex">
                {metaDataSection(optionalFields, reload)}
              </div>
            </TabPanel>
          </ContentTabsWrapper>
        ) : (
          <>{mainSection()}</>
        )}
      </div>
      <ContentTabsWrapper
        tabIndex={tabIndex}
        tabItems={tabItems}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabPanel>
          <div className="px-4">
            <ActionsList
              projectId={projectId}
              parentType={'Delivery'}
              parentId={deliveryId}
              parentDone={delivery.status === 'done'}
              tableName={'DeliveryActionTable'}
              onOpenItem={onOpenItem}
              reloadTree={reloadTree}
              module={'planning'}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <ChangeLogList id={deliveryId} parentType={'Delivery'} />
        </TabPanel>
        <TabPanel className={'noTabPanelPadding'}>
          <DependencyDelivery
            showDependentOn={true}
            deliveryId={delivery.id ?? 0}
            projectId={projectId}
            ErrorMessage={getDependencyError()}
          />
        </TabPanel>
        <TabPanel className={'noTabPanelPadding'}>
          <DependencyDelivery
            showDependentOn={false}
            deliveryId={delivery.id ?? 0}
            projectId={projectId}
            ErrorMessage={getDependencyError()}
          />
        </TabPanel>
        <TabPanel className={'noTabPanelPadding'}>
          <div className="pl-4">
            <DocumentsList
              projectId={projectId}
              parentId={deliveryId}
              parentType={'Delivery'}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="px-4">
            <Comments parentType="Delivery" parentId={deliveryId} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="px-4">
            <ChangeLog parentType="Delivery" parentId={deliveryId} />
          </div>
        </TabPanel>
      </ContentTabsWrapper>
      {showQrModal && (
        <QrModal
          show={showQrModal}
          close={() => setShowQrModal(false)}
          title={`${delivery.record_id} ${delivery.name}`}
          value={qrString}
        />
      )}

      {changeLogModal ? (
        <KeypointChangeLogModal
          show={changeLogModal}
          toggleChangeLogModal={onCancelChangelogModal}
          onChangeLogSubmit={onChangeLogSubmit}
          onChangeReasonLog={onChangelogReasonChange}
        />
      ) : null}

      {showImprovementPanel && delivery.improvement_id && (
        <ImprovementPanel
          projectId={projectId}
          improvementId={delivery.improvement_id}
          onClose={toggleImprovementPanel}
          show={showImprovementPanel}
        />
      )}
    </PageRoot>
  )
}

export default DeliveryPage
