import { TFunction } from 'i18next'
import { capitalize } from 'lodash'
import { IStatusTypes } from 'src/components/status-dropdown/TableStatusDropdown'
import { TestExecutionStatus } from 'src/page/systematic-completion/tests/TestExecutionList'
import { capFirstLetter } from '../utility/utils'

export interface ITypes {
  id: string
  name: string
}

export interface IFilterType extends ITypes {
  value: string
}

export const priorities = (t: any): ITypes[] => [
  {
    id: 'critical',
    name: t('critical'),
  },
  {
    id: 'non_critical',
    name: t('non_critical'),
  },
]

export const actionStatus = (t: any): ITypes[] => [
  {
    id: 'open',
    name: t('open'),
  },
  {
    id: 'in_progress',
    name: t('in_progress'),
  },
  {
    id: 'done',
    name: t('done'),
  },
]

export const testStatus = (t: any): ITypes[] => {
  return Object.values(TestExecutionStatus).map((status) => {
    return {
      id: status,
      name: t(status),
    }
  })
}

export const constructionStatus = (t: any): ITypes[] => [
  {
    id: 'open',
    name: t('open'),
  },
  {
    id: 'in_progress',
    name: t('in_progress'),
  },
  {
    id: 'done',
    name: t('done'),
  },
]

export const actionSource = (
  t: TFunction<'translation', undefined>,
): IFilterType[] => {
  return [
    {
      id: 'Delivery',
      name: capFirstLetter(t('delivery')),
      value: 'delivery',
    },
    {
      id: 'Risk',
      name: capFirstLetter(t('risk')),
      value: 'risk',
    },
    {
      id: 'Topic',
      name: capFirstLetter(t('meeting')),
      value: 'topic',
    },
    {
      id: 'Project',
      name: capFirstLetter(t('project')),
      value: 'project',
    },
    {
      id: 'ChecklistParameter',
      name: capFirstLetter(t('board_meeting')),
      value: 'board_meeting',
    },
    {
      id: 'CheckInParameter',
      name: capFirstLetter(t('check_in_out')),
      value: 'check_in_out',
    },
    {
      id: 'ConstructionLocomotive',
      name: capFirstLetter(t('wagon')),
      value: 'wagon',
    },
    {
      id: 'ControlArea',
      name: capFirstLetter(t('control_area')),
      value: 'control_area',
    },
    {
      id: 'System',
      name: capFirstLetter(t('system')),
      value: 'system',
    },
    {
      id: 'Room',
      name: capFirstLetter(t('room')),
      value: 'room',
    },
    {
      id: 'Test',
      name: capFirstLetter(t('test')),
      value: 'test',
    },
    {
      id: 'TestCaseExecution',
      name: capFirstLetter(t('test_execution')),
      value: 'test_execution',
    },
    {
      id: 'TaskStep',
      name: capitalize(t('step')),
      value: 'task_step',
    },
    {
      id: 'ContractBorder',
      name: capitalize(t('contractual_border')),
      value: 'contract_border',
    },
  ]
}

export const words = (t: any): ITypes[] => [
  {
    id: 'open',
    name: t('open'),
  },
  {
    id: 'in_progress',
    name: t('in_progress'),
  },
  {
    id: 'done',
    name: t('done'),
  },
  {
    id: 'behind_schedule',
    name: t('delayed'),
  },
  {
    id: 'expired',
    name: t('expired'),
  },
  {
    id: 'deadline',
    name: t('deadline'),
  },
  {
    id: 'endTime',
    name: t('deadline'),
  },
  {
    id: 'status',
    name: t('status'),
  },
  {
    id: 'responsible',
    name: t('responsible'),
  },
  {
    id: 'contract',
    name: t('contract'),
  },
  {
    id: 'system',
    name: t('system'),
  },
  {
    id: 'room',
    name: capFirstLetter(t('room')),
  },
  {
    id: 'name',
    name: t('name'),
  },
  {
    id: 'title',
    name: t('title'),
  },
  {
    id: 'description',
    name: t('description'),
  },
  {
    id: 'assumptions',
    name: t('assumptions'),
  },
  {
    id: 'notes',
    name: t('result'),
  },
  {
    id: 'main_process',
    name: capFirstLetter(t('main_process')),
  },
  {
    id: 'mile_stone',
    name: t('milestone'),
  },
  {
    id: 'team',
    name: t('theme'),
  },
  {
    id: 'discipline',
    name: t('discipline'),
  },
  {
    id: 'risk_owner',
    name: t('responsible'),
  },
  {
    id: 'phase',
    name: t('phase'),
  },
  {
    id: 'incident',
    name: t('event'),
  },
  {
    id: 'cause',
    name: t('cause'),
  },
  {
    id: 'impact',
    name: t('impact'),
  },
  {
    id: 'new_open',
    name: t('new_suggestions'),
  },
  {
    id: 'prioritized',
    name: t('prioritization'),
  },
  {
    id: 'to_decsion',
    name: t('for_decision'),
  },
  {
    id: 'ongoing',
    name: t('under_construction'),
  },
  {
    id: 'completed',
    name: t('completed'),
  },
  {
    id: 'non-prioritized',
    name: t('not_a_priority'),
  },
  {
    id: 'is_top_ten',
    name: t('top_risk'),
  },
  {
    id: 'is_project_risk',
    name: t('project_risk'),
  },
  {
    id: '0',
    name: t('no'),
  },
  {
    id: '1',
    name: t('yes'),
  },
  {
    id: 'prioritized',
    name: t('priority'),
  },
  {
    id: 'priority',
    name: t('priority'),
  },
  {
    id: 'closed_risk_occured',
    name: t('closed_risk_occured'),
  },
  {
    id: 'closed_risk_not_occured',
    name: t('closed_risk_not_occured'),
  },
  {
    id: 'open_improvement',
    name: t('new_suggestions'),
  },
  {
    id: 'prioritized',
    name: t('prioritization'),
  },
  {
    id: 'completed',
    name: t('completed'),
  },
  {
    id: 'high',
    name: t('high'),
  },
  {
    id: 'medium',
    name: 'Medium',
  },
  {
    id: 'low',
    name: t('low'),
  },
  {
    id: 'rejected',
    name: t('rejected'),
  },
  {
    id: 'measure',
    name: t('wanted_situation'),
  },
  {
    id: 'effect',
    name: t('effect'),
  },
  {
    id: 'improvement_effect',
    name: t('benefit'),
  },
  {
    id: 'prioritization_description',
    name: t('justification_for_cost/benefit'),
  },
  {
    id: 'improvement_cost',
    name: t('improvement_cost'),
  },
  {
    id: 'decison_description',
    name: t('decison_description'),
  },
  {
    id: 'implementation_description',
    name: t('description_of_the_implementation'),
  },
  {
    id: 'background',
    name: capFirstLetter(t('background')),
  },
  {
    id: 'current_situation',
    name: capFirstLetter(t('current_situation')),
  },
  {
    id: 'profitability',
    name: t('profitability'),
  },
  {
    id: 'countermeasures',
    name: t('countermeasures'),
  },
  {
    id: 'plan',
    name: t('plan'),
  },
  {
    id: 'expired_children',
    name: 'Utgåtte barn',
  },
  {
    id: 'open_children',
    name: 'Åpne barn',
  },
  {
    id: 'delay',
    name: 'Forsinkelse',
  },
]

export const statusFilterTypes = (t: any) => [
  {
    id: 'open',
    name: t('open'),
  },
  {
    id: 'in_progress',
    name: t('in_progress'),
  },
  {
    id: 'done',
    name: t('done'),
  },
  {
    id: 'closed_risk_occured',
    name: t('closed_risk_occured'),
  },
  {
    id: 'closed_risk_not_occured',
    name: t('closed_risk_not_occured'),
  },
  {
    id: 'open_improvement',
    name: t('new_suggestions'),
  },
  {
    id: 'prioritized',
    name: t('prioritized'),
  },
  {
    id: 'completed',
    name: t('completed'),
  },
]

export const riskStatusTypes = (t: any): IStatusTypes[] => [
  {
    id: 'open',
    name: t('open'),
  },
  {
    id: 'closed_risk_occured',
    name: t('closed_risk_occured'),
  },
  {
    id: 'closed_risk_not_occured',
    name: t('closed_risk_not_occured'),
  },
]

export const cleanStatusTypes = (t: any): IStatusTypes[] => [
  {
    id: 'not_clean_zone',
    name: t('not_clean_zone'),
  },
  {
    id: 'red_clean_zone',
    name: t('red_clean_zone'),
  },
  {
    id: 'yellow_clean_zone',
    name: t('yellow_clean_zone'),
  },
]

export const effectTypes = (t: any) => [
  {
    id: 'high',
    name: t('high'),
  },
  {
    id: 'medium',
    name: t('medium'),
  },
  {
    id: 'low',
    name: t('low'),
  },
]

export const improvementStatusTypes = (t: any) => [
  {
    id: 'open_improvement',
    name: t('new_suggestions'),
  },
  {
    id: 'in_progress',
    name: t('in_progress'),
  },
  {
    id: 'completed',
    name: t('completed'),
  },
]

export const costTypes = (t: any) => [
  {
    id: 'high',
    name: t('high>50K'),
  },
  {
    id: 'medium',
    name: t('medium_between_50k_n_50K'),
  },
  {
    id: 'low',
    name: t('low_<5K'),
  },
]

export const booleanTypes = (t: any) => [
  {
    id: true,
    name: t('yes'),
  },
  {
    id: false,
    name: t('no'),
  },
]

export const closedStatusTypes = [
  'done',
  'closed_risk_occured',
  'closed_risk_not_occured',
]

export const trainColors = (t: any) => [
  {
    id: 'blue',
    name: t('blue'),
  },
  {
    id: 'blue-light',
    name: t('blue_light'),
  },
  {
    id: 'aero',
    name: t('aero'),
  },
  {
    id: 'air_blue',
    name: t('air_blue'),
  },
  {
    id: 'fluorescent_blue',
    name: t('fluorescent_blue'),
  },
  {
    id: 'blue_gray',
    name: t('blue_gray'),
  },
  {
    id: 'ice_blue',
    name: t('ice_blue'),
  },
  {
    id: 'violet',
    name: t('violet'),
  },
  {
    id: 'pink',
    name: t('pink'),
  },
  {
    id: 'orange',
    name: t('orange'),
  },
  {
    id: 'cyan',
    name: t('cyan'),
  },
  {
    id: 'brown',
    name: t('brown'),
  },
  {
    id: 'brown_two',
    name: t('brown_two'),
  },
  {
    id: 'purple',
    name: t('purple'),
  },
  {
    id: 'lime',
    name: t('lime'),
  },
  {
    id: 'indigo',
    name: t('indigo'),
  },
  {
    id: 'deep-orange',
    name: t('deep_orange'),
  },
  {
    id: 'blue-gray',
    name: t('blue_gray'),
  },
  {
    id: 'amber',
    name: t('amber'),
  },
  {
    id: 'teal',
    name: t('teal'),
  },
]

export const taskUnits = (t: any) => [
  {
    id: 'm_2',
    name: t('m_2'),
  },
  {
    id: 'm_3',
    name: t('m_3'),
  },
  {
    id: 'M',
    name: t('meter'),
  },
  {
    id: 'count',
    name: t('item_count'),
  },
]

export const statusTypes = (t: any): IStatusTypes[] => [
  {
    id: 'open',
    name: capFirstLetter(t('open')),
  },
  {
    id: 'in_progress',
    name: capFirstLetter(t('in_progress')),
  },
  {
    id: 'done',
    name: capFirstLetter(t('done')),
  },
]

export const systemStatusTypes = (t: any): IStatusTypes[] => [
  ...statusTypes(t),
  {
    id: 'not_relevant',
    name: capFirstLetter(t('not_relevant')),
  },
  {
    id: 'error',
    name: capFirstLetter(t('error')),
  },
]

export const contractBorderstatusTypes = (t: TFunction): IStatusTypes[] => [
  {
    id: 'open',
    name: capitalize(t('open')),
  },
  {
    id: 'closed',
    name: capitalize(t('closed')),
  },
]

export const contractBorderTypes = (t: TFunction): IStatusTypes[] => [
  {
    id: 'technical',
    name: capitalize(t('technical')),
  },
  {
    id: 'non-technical',
    name: capitalize(t('non-technical')),
  },
]
