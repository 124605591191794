import * as React from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ISystemType } from 'src/service/OrgTypes'
import FolderMetadataRow from '../../../document/components/Folders/CreateFolderModal/FolderMetadataRow'
import { IMetaData } from '../../../document/types/IMetaData'

interface ISystemTypeFieldGroupProps {
  control: Control<FieldValues, ISystemType>
}

const SystemTypeFieldsGroup: React.FC<ISystemTypeFieldGroupProps> = ({
  control,
}) => {
  const { t } = useTranslation()
  return (
    <Controller
      render={({ field: { value, onChange } }) => (
        <div className="py-2">
          {value?.length && (
            <p className="px-2 font-medium text-sm">{t('metadata')}</p>
          )}
          <div className="flex flex-col gap-2">
            {value?.map((field: IMetaData) => {
              return (
                <div key={field.id}>
                  <FolderMetadataRow
                    field={field as unknown as IMetaData}
                    updateMetadata={(fieldId, updatedMetaData) => {
                      const index = value.findIndex(
                        (meta: IMetaData) => meta.id === fieldId,
                      )
                      value[index] = updatedMetaData
                      onChange(value)
                    }}
                    disabled
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}
      name={'optional_fields'}
      control={control}
    />
  )
}

export default SystemTypeFieldsGroup
